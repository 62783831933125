<template>
  <div class="bg-white py-md-2 py-2 px-md-4 position-relative mb-3">
    <div class="content-wrapper  px-3">
      <div class="d-flex align-items-center mb-2">
        <router-link class="pointer h5 m-0" :to="`/system/questions/list`">
          <b-icon icon="arrow-left" class="mr-2" />
        </router-link>
        <section>
          <span class="d-block text-muted">Questionário</span>
          <h5 class="m-0">{{ current_question.name }}</h5>
        </section>
      </div>
      <hr />
      <div
        class="mt-3 d-flex w-100 justify-content-between align-items-center mb-2"
      >
        <p class="text-muted mb-0">{{ current_question.description }}</p>
        <div class="d-flex align-items-center gap-3">
          <b-button size="sm" variant="light" @click="handleEditForm">
            Editar
          </b-button>
          <b-button size="sm" variant="danger" @click="handleOpenConfirmDelte">
            Excluir
          </b-button>
        </div>
      </div>
    </div>
    <b-modal
      id="edit-question-type"
      centered
      hide-footer
      size="md"
      no-close-on-backdrop
      :title="`Questionário`"
    >
      <Form :id="formId" :isAdd="false" @submited="handleCloseModal" />
    </b-modal>
    <b-modal id="delete-question-type" centered size="sm">
      <template #modal-header> <h5>Atenção!</h5></template>
      <p>
        Você apagará o questionário <strong>{{ current_question.name }}</strong
        >, deseja continuar?
      </p>
      <template #modal-footer="{ close }">
        <b-button variant="link" @click="handleDeleteForm()">
          Sim
        </b-button>
        <b-button variant="primary" @click="close()">Não</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Form from "./form.vue";

export default {
  components: {
    Form,
  },
  props: {
    current_question: {
      type: [Object],
      required: true,
    },
  },

  data() {
    return {
      formId: "",
    };
  },
  computed: {
    ...mapGetters(["is_mobile"]),
  },
  methods: {
    ...mapActions(["hide_application_header", "get_form", "delete_question_type"]),
    handleOpenConfirmDelte() {
      this.$bvModal.show("delete-question-type");
    },

    handleEditForm() {
      this.$bvModal.show("edit-question-type");
    },
    handleCloseModal() {
      this.$bvModal.hide("edit-question-type");
    },
    handleDeleteForm() {
      this.delete_question_type(this.formId).then(() => {
        this.$bvModal.hide("delete-question-type");
        this.$router.push("/system/questions/list");
      });
    },
  },

  beforeDestroy() {
    this.hide_application_header(true);
  },
  mounted() {
    this.formId = this.$route.params.id;
    this.hide_application_header(false);
  },
};
</script>
