<template>
  <div>
    <Header :current_question="current_question" />
    <div class="body d-md-flex justify-content-between content-wrapper px-3">
      <div class="questions w-100">
        <div class="d-md-flex align-items-center justify-content-between">
          <h5>
            Perguntas <small>({{ questions.length }})</small>
          </h5>
          <b-button
            @click="handleAddQuestions()"
            class="btn-add"
            v-if="!is_provider"
            :disabled="inRegister"
          >
            Adicionar
          </b-button>
        </div>
        <div class="questions-list mt-3 w-100">
          <skeleton
            height="80px"
            grid="1"
            gap="10"
            :rows="5"
            v-if="questions.length === 0 && !emptyQuestions"
          />
          <empty-list
            v-if="emptyQuestions && !inRegister"
            text="Não há perguntas cadastradas"
          />

          <b-card
            class="w-100 py-2 px-4 mb-4"
            border-variant="primary"
            no-body
            v-if="inRegister"
          >
            <strong class="text-primary d-block mb-3">Adicionar pergunta</strong>
            <section
              class="
                d-block d-md-flex
                align-items-center
                justify-content-between
                flex-md-row
              "
            >
              <section class="w-100 mr-4 mb-3 mb-md-0">
                <span for="">Pergunta</span>
                <b-form-textarea
                  class="mt-1"
                  autofocus
                  v-model="newQuestion.quest"
                  :disabled="is_provider"
                />
              </section>
              <section class="mb-3 mb-md-0 w-100">
                <span for="">Observação</span>
                <b-form-textarea
                  v-model="newQuestion.observation"
                  class="mt-1"
                  :disabled="is_provider"
                  label="name"
                  placeholder="Observação da pergunta"
                >
                </b-form-textarea>
              </section>
            </section>
            <div
              class="icon-action text-center d-flex justify-content-end mt-3"
              v-if="!is_provider"
            >
              <b-button size="sm" variant="light" @click="handleCancelRegister()">
                Cancelar
              </b-button>
              <b-button
                :disabled="newQuestion.quest.length < 5 || newQuestion.observation.length < 5"
                size="sm"
                variant="primary"
                @click="handleUpdateQuestion(newQuestion, 0)"
              >
                Salvar
              </b-button>
            </div>
          </b-card>

          <b-card
            v-for="(question, index) in questions"
            :key="index"
            class="w-100 py-2 px-4 mb-2"
            no-body
            v-disable="inRegister"
          >
            <section
              class="
                d-block d-md-flex
                align-items-center
                justify-content-between
                flex-md-row
              "
            >
              <section class="w-100 mr-4 mb-3 mb-md-0">
                <span for="">Pergunta</span>
                <b-form-textarea
                  class="mt-1"
                  :disabled="is_provider || inRegister"
                  v-model="question.quest"
                  @change="handleUpdateQuestion(question, index)"
                />
              </section>
              <section class="mb-3 mb-md-0 mr-md-4 w-100">
                <span for="">Observação</span>
                <b-form-textarea
                  class="mt-1"
                  :disabled="is_provider || inRegister"
                  v-model="question.observation"
                  label="name"
                  @change="handleUpdateQuestion(question, index)"
                  placeholder="Observação da pergunta"
                >
                </b-form-textarea>
              </section>

              <span
                class="icon-action text-center d-block"
                v-if="!is_provider && !inRegister"
              >
                <b-spinner
                  v-if="selectedId === question.id"
                  type="grow"
                  small
                  class="h6 ml-3 mb-0"
                />
                <b-icon
                  v-else
                  @click="handleDeleteQuestion(question.id)"
                  v-b-popover.hover.top="'Excluir'"
                  class="h6 ml-3 pointer mb-0"
                  icon="trash"
                />
              </span>
            </section>
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Header from "./Header";
import _ from "lodash";

export default {
  components: {
    Header
  },
  props: {
    current_question: {
      type: [Object],
      required: true,
    },
  },

  data() {
    return {
      selectedId: null,
      inRegister: false,
      emptyQuestions: false,
      loading: false,
      selectedQuestionType: "",
      questionsTypes: [],
      questions: [],
      sourceOptions: [],
      question_id: null,
      newQuestion: {
        quest: "",
        observation: "",
      },
    };
  },
  methods: {
    ...mapActions([
      "get_all_questions_type",
      "add_question",
      "get_all_questions",
      "edit_question",
      "delete_question",
    ]),

    handleSelectQuestionType(qst) {
      this.selectedQuestionType = qst;
      this.handleGetQuestions();
      this.$refs.context.close();
    },

    handleAddQuestions() {
      this.inRegister = true;
      this.emptyQuestions = this.questions.length === 0;
    },
    handleCancelRegister() {
      this.inRegister = false;
      this.newQuestion = {
        quest: "",
        observation: "",
      };
    },

    addQuestion(form) {
      this.add_question({ ...form }).then(() => {
        this.handleGetQuestions();
        this.handleCancelRegister();
      });
    },
    editQuestion(form) {
      this.edit_question({ ...form }).then(() => {
        this.handleGetQuestions();
        this.handleCancelRegister();
      });
    },
    update({ id, quest, observation }, order) {
      if (quest.length < 5 || observation.length < 5) {
        return;
      }
      const data = {
        quest,
        observation: observation,
        type: this.question_id,
        order,
      };

      if (id) {
        this.editQuestion({ ...data, id });
      } else {
        this.addQuestion(data);
      }
    },

    handleUpdateQuestion: _.debounce(function(value, order) {
      this.update(value, order);
    }, 500),

    handleGetQuestions() {
      this.loading = true;
      const type = this.question_id;
      this.get_all_questions({ type }).then(({ results }) => {
        this.loading = false;
        this.emptyQuestions = results.length === 0;
        this.questions = results.map((question) => {
          return {
            ...question,
          };
        });
      });
    },
    handleDeleteQuestion(id) {
      this.selectedId = id;
      this.delete_question(id).then(() => {
        this.handleGetQuestions();
      });
    },
  },
  computed: {
    ...mapGetters(["is_provider"]),
  },
  mounted() {
    this.question_id = this.$route.params.id;
    this.handleGetQuestions();
  },
};
</script>
<style lang="scss" scoped>
.questions-type {
  strong {
    text-align: left;
    line-height: 1;
  }
}
</style>
